<template>
  <div>
    <Header></Header>
    <div class="cpal1">
      <img class="cpalA1" src="@/assets/cpal.png" alt="" />
      <div class="cpalB1">产品案例 - 分包商考核管理平台</div>
      <div class="cpalC1">SUBCONTRACTOR ASSESSMENT MANAGEMENT</div>
    </div>
    <div class="body">
      <div>
        <div class="cpszh">分包商考核管理平台</div>
        <div class="bodyTitle">
          <div class="product">SUBCONTRACTOR ASSESSMENT MANAGEMENT</div>
          <!-- <div class="more">
            <div class="datetime">2022-10-23</div>
          </div> -->
        </div>
      </div>
      <div class="bodyContent">
        <div>
          <img class="cpalb1" src="@/assets/分包商考核管理平台.png" alt="" />
        </div>
        <div class="fontA11">
          &emsp;&emsp;随着数据化管理在各行各业慢慢的普及，大部分企业都开始迎接这汹涌的浪潮，踏上了“数字化”、“信息化”转型的道路，放眼工程建设行业的企业来看，分包商资源的管理在和“信息化”这三个字结合的过程中，从业务应用层面出发，大致可以分成三个阶段：资源的录入阶段、汇总分析阶段和系统调用共享阶段，而这三个阶段和信息化的映射关系，分别为：“系统建设”、“数据分析”、“资源管理”，目前市面上已经有很多针对第一个阶段的产品解决方案，笔者就不在过多的赘述，而关于“数据分析”和“资源管理”是本文接下来可以浅谈一二的。
        </div>
      </div>
      <div class="LastNext">
        <div class="last" @click="nineL">上一案例：{{ last }}</div>
        <div class="next" @click="nineN">下一案例：{{ next }}</div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/header.vue";
import Footer from "@/views/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      last: "执法办案云平台",
      next: "智慧校园",
    };
  },
  methods: {
    nineL() {
        this.$router.push({ name: "productCase8" });
    },
    nineN() {
        this.$router.push({ name: "productCase10" });
    }
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.lbt {
  width: 100%;
}
.body {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;
  background: #fff !important;
}
.cpszh {
  font-size: 24px;
  padding: 40px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.bodyTitle {
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
}
.product {
  font-size: 24px;
  color: #bfbfbf;
  margin-left: 32px;
  letter-spacing: 1px;
}
.more {
  display: flex;
}
.datetime {
  font-size: 20px;
  color: #7d7d7d;
  letter-spacing: 1px;
}
.bodyContent {
  display: block;
  border-bottom: 1px solid #d8d8d8;
}
.cpalb1 {
    width: 100%;
  margin: 24px 32px;
}
.fontA1 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 0 32px;
  line-height: 40px;
}
.fontA11 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 46px 32px;
  line-height: 40px;
}
.LastNext {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  font-size: 20px;
  color: #7d7d7d;
  letter-spacing: 1px;
}
.last {
    cursor: pointer;
}
.next {
    cursor: pointer;
}
.cpal1 {
  position: relative;
}
.cpalA1 {
  width: 100%;
}
.cpalB1 {
  position: absolute;
  top: 200px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 60px;
  color: #fff;
  line-height: 33px;
  letter-spacing: 1px;
}
.cpalC1 {
  position: absolute;
  top: 280px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 24px;
  color: #bfbfbf;
  line-height: 33px;
  letter-spacing: 1px;
}
</style>
